var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"mb-10",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h5 primary--text"},[_vm._v("สินค้าเพิ่มตัง")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มรายการ")],1)],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","item-key":"_id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category == "0" ? "หลัก" : "")+" "+_vm._s(item.category == "1" ? "ทั่วไป" : "")+" ")]}},{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_type == "0" ? "ครั้งเดียว" : "")+" "+_vm._s(item.product_type == "1" ? "รายเดือน" : "")+" "+_vm._s(item.product_type == "2" ? "รายปี" : "")+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price,'0,0.00'))+" ")]}},{key:"item.bills",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_unlimit ? 'Unlimit' : item.bills)+" ")]}},{key:"item.is_available",fn:function(ref){
var item = ref.item;
return [_c('menu-ship',{attrs:{"path":"/backend/product/changed_product_status","params":{ _id: item._id}},on:{"success":function($event){_vm.$alertSuccess(), _vm.getData()}},model:{value:(item.is_available),callback:function ($$v) {_vm.$set(item, "is_available", $$v)},expression:"item.is_available"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }